import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { ChangeStepHandler, SaveStepperHandler } from 'templates/StepWizard_V2/types';
import { EstateFormInterface, EstateFormProps, EstateStepName } from '../types';
import useEstateDisabledSteps from './useEstateDisabledSteps';
import useEstateStepsErrors from './useEstateStepsErrors';
import useSubmitEstateForm from './useSubmitEstateForm';
import { getEstateCompositionValue } from './utils';

interface Props {
  onSaveSuccess: EstateFormProps['handleSave'];
  stepsIndex: Record<EstateStepName, number>;
}

const useEstateSteps = ({ onSaveSuccess, stepsIndex }: Props) => {
  const stepsErrors = useEstateStepsErrors(stepsIndex);
  const disabledSteps = useEstateDisabledSteps(stepsIndex);
  const submitForm = useSubmitEstateForm(onSaveSuccess);

  const { prevFormState, setPrevFormState } = useContext(EstateFormContext);
  const { handleSubmit, setValue } = useFormContext<EstateFormInterface>();
  const { isAnyDocumentEmpty, markEmptyDocuments } = useContext(FilesUploadContext);

  const onSave: SaveStepperHandler = (event, reset, currentStep) =>
    handleSubmit((formData) => {
      let data = {
        ...formData,
        estateDetails: formData.estateDetails
          ? {
              ...formData.estateDetails,
              hasConformity: !!formData.estateDetails.conformity,
            }
          : undefined,
      };
      switch (currentStep) {
        case stepsIndex.general: {
          const estateComposition = getEstateCompositionValue(data, prevFormState);
          data = {
            ...data,
            estateDetails: data.estateDetails
              ? { ...data.estateDetails, estateComposition }
              : undefined,
          };
        }
      }

      return submitForm(reset)(data, event);
    })(event);

  const onChangeStep: ChangeStepHandler = (event, changeStep, currentStep) =>
    handleSubmit((data) => {
      setPrevFormState(structuredClone(data));

      switch (currentStep) {
        case stepsIndex.general: {
          const value = getEstateCompositionValue(data, prevFormState);
          setValue('estateDetails.estateComposition', value);
          return changeStep();
        }
        case stepsIndex.documents:
          return isAnyDocumentEmpty ? markEmptyDocuments() : changeStep();
        default:
          return changeStep();
      }
    })(event);

  const onBack: ChangeStepHandler = (event, changeStep, currentStep) =>
    handleSubmit((data) => {
      setPrevFormState(structuredClone(data));

      if (currentStep === stepsIndex.documents) {
        return isAnyDocumentEmpty ? markEmptyDocuments() : changeStep();
      }
      return changeStep();
    })(event);

  return {
    stepsErrors,
    disabledSteps,
    onNext: onChangeStep,
    onLabel: onChangeStep,
    onBack,
    onSave,
  };
};

export default useEstateSteps;
