import { ProductInterface } from 'api/dossier/products/types';
import { FetchedDossierChecklistItem } from 'api/dossier/types';

export const getProductButtonGridSize = (productsCount: number) => {
  switch (productsCount) {
    case 1:
      return 12;
    case 2:
      return 6;
    default:
      return 4;
  }
};

export const shouldProductBeDisabled = (
  products: ProductInterface[],
  { name, multiple, parentId }: FetchedDossierChecklistItem,
) => {
  // If product depends on its parent, it should not be able to add them
  // e.g. FireInsurance and LegalAssistance depends on RentalDeposit
  if (parentId) {
    return true;
  }
  // When product is not multiple, it can be added just once
  if (!multiple) {
    return products.some((product) => product.productName === name);
  }
  // Multiple products can be added again only when product has status different than New or Draft
  const product = products.find(({ productName }) => productName === name);
  return product?.status === 'New' || product?.status === 'Draft';
};
