import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Button from 'atoms/Button';
import useGoogleParams from 'hooks/useAddressFields/useGoogleParams';
import usePostCodesAndCities from 'hooks/useAddressFields/usePostCodesAndCities';
import useStreetTranslation from 'hooks/useAddressFields/useStreetTranslation';
import { getInitPostCodeAndCityParams } from 'hooks/useAddressFields/utils';
import useFormMethods from 'hooks/useFormMethods';
import { RestoreIcon } from 'icons';
import { AddressFormInterface } from 'api/common/types';
import GooglePlacesSearch from 'components/inputs/GooglePlacesSearch';
import { GooglePlaceDetails } from 'components/inputs/GooglePlacesSearch/types';
import AddressRawFields from './AddressRawFields';
import { AddressFieldsWithGoogleProps } from './types';
import { getAddressFields } from './utils';

const AddressFieldsWithGoogle = ({
  disableCountrySelection = false,
  onCountryChange,
  prefix,
  clearButtonTranslationLabel = 'clearAddress',
}: Partial<AddressFieldsWithGoogleProps>) => {
  const [googleInput, setGoogleInput] = useState('');
  const [clearingFields, setClearingFields] = useState(false);

  const { t } = useTranslation('common');
  const { resetField, formState } = useFormMethods<{ address: AddressFormInterface }>(
    prefix,
  );

  const {
    fetchPostCodesWithCities,
    updatePostCodeAndCityParams,
    ...postCodesAndCitiesProps
  } = usePostCodesAndCities(prefix);
  const { fetchStreetTranslation } = useStreetTranslation(prefix);
  const { updateAddressWithGoogleParams, updatingAddressFields } = useGoogleParams({
    prefix,
    fetchPostCodesWithCities,
    fetchStreetTranslation,
  });

  const onChange = ({ address_components }: GooglePlaceDetails) => {
    const fields = getAddressFields(address_components);
    updateAddressWithGoogleParams(fields);
  };

  const handleClear = async () => {
    setClearingFields(true);
    const initParams = getInitPostCodeAndCityParams(formState.defaultValues?.address);
    await fetchPostCodesWithCities(initParams);
    updatePostCodeAndCityParams(initParams);
    resetField('address');
    setGoogleInput('');
    setClearingFields(false);
  };

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <GooglePlacesSearch
          onChange={onChange}
          country={disableCountrySelection ? 'be' : undefined}
          input={googleInput}
          setInput={setGoogleInput}
          loading={updatingAddressFields}
        />
      </Grid>
      <AddressRawFields
        prefix={prefix}
        disableCountrySelection={disableCountrySelection}
        onCountryChange={onCountryChange}
        fetchStreetTranslation={fetchStreetTranslation}
        fetchPostCodesWithCities={fetchPostCodesWithCities}
        updatePostCodeAndCityParams={updatePostCodeAndCityParams}
        disableFields={updatingAddressFields}
        {...postCodesAndCitiesProps}
      />
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleClear}
          role="secondary"
          startIcon={<RestoreIcon />}
          loading={clearingFields}
          // TODO: formsState.dirtyFields does not work correctly - getValues() and formState.defaultValues do not match
          // Text/Number input can have default value as undefined/null. After passing some value to the input and then clearing it,
          // getValues() returns empty string and it does not match with undefined/null from default values and field is dirty
          disabled={!formState.dirtyFields?.address}
        >
          {t(clearButtonTranslationLabel, 'clearAddress')}
        </Button>
      </Grid>
    </>
  );
};

export default AddressFieldsWithGoogle;
