import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { getErrorMessage } from '../utils';
import TextInput from './TextInput';
import { ControlledTextInputProps } from './types';

const ControlledTextInput: <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControlledTextInputProps<T, N>,
) => React.ReactElement = ({
  name,
  error,
  warning,
  helperText,
  onBlur: handleBlur,
  onChange: handleChange,
  control,
  ...inputProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref, value, onChange, onBlur, ...field } }) => (
      <TextInput
        value={value ?? ''}
        // TODO: Consider changing arguments of onChange into onChange: (value: string, e: Event) => void. It would be similar to Select input
        onChange={(e) => {
          onChange(e.target.value);
          handleChange?.(e);
        }}
        onBlur={(e) => {
          onBlur();
          handleBlur?.(e);
        }}
        inputRef={ref}
        error={!!error}
        warning={!!warning}
        helperText={getErrorMessage(error ?? warning) || helperText}
        {...inputProps}
        {...field}
      />
    )}
  />
);

export default ControlledTextInput;
