import { BaseSyntheticEvent, useContext, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { ConfirmationContext } from 'contexts/confirmation/ConfirmationContext';
import { EstateFormContext } from 'contexts/estateForm/estateFormContext';
import { FilesUploadContext } from 'contexts/fileUpload/filesUploadContext';
import { UploadPhotoContext } from 'contexts/photoUpload/uploadPhotoContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import routes from 'router/routes';
import { addEstate, updateEstate, updateEstateDetails } from 'api/estate/requests';
import { EstateFormInterface, EstateFormProps } from '../types';

const useSubmitEstateForm = (onSaveSuccess: EstateFormProps['handleSave']) => {
  const {
    estateId,
    areCopiedOwnersUnchanged,
    shouldResetFormAfterSave,
    setShouldResetFormAfterSave,
    saveOwners,
    copyOwners,
    isCopy,
    setIsCopy,
  } = useContext(EstateFormContext);

  const [savedId, setSavedId] = useState(estateId);

  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const { uploadPhoto } = useContext(UploadPhotoContext);
  const { setUpAndOpenConfirmationModal } = useContext(ConfirmationContext);
  const {
    saveDocuments,
    clearDocuments,
    isAnyDocumentEmpty,
    markEmptyDocuments,
    documents,
    validateDocuments,
  } = useContext(FilesUploadContext);

  const { reset, formState } = useFormContext<EstateFormInterface>();

  const saveForm = async (
    event: BaseSyntheticEvent | undefined,
    { estate, estateDetails }: EstateFormInterface,
    resetStepper: () => void,
  ) => {
    let isAnyDocumentToSave = false;
    let shouldProceed = true;
    let estateId = savedId;

    if (documents.length && documents[0].documentType) {
      const onValid = () => {
        isAnyDocumentToSave = true;
      };
      const onError = () => {
        shouldProceed = false;
      };
      await validateDocuments(event, onValid, onError);
    }

    const isEstateDirty = !isDeepEqual(estate, formState.defaultValues?.estate);

    if (shouldProceed && (isEstateDirty || isCopy)) {
      if (estateId) {
        const { ok } = await updateEstate(estateId, estate);
        shouldProceed = ok;
      } else {
        const { ok, response } = await addEstate(estate);
        shouldProceed = ok;
        estateId = ok ? response.id : null;
      }
    }

    if (shouldProceed && estateId) {
      setSavedId(estateId);
      reset(
        { estate, estateDetails },
        {
          keepDirty: shouldResetFormAfterSave,
          keepDirtyValues: shouldResetFormAfterSave,
          keepValues: shouldResetFormAfterSave,
        },
      );

      const isEstateDetailsDirty = !isDeepEqual(
        estateDetails,
        formState.defaultValues?.estateDetails,
      );

      if (estateDetails && isEstateDetailsDirty) {
        const { ok } = await updateEstateDetails(estateId, estateDetails);
        shouldProceed = ok;
      }

      const ownersSaved = await saveOwners(estateId);
      const photoUploaded = await uploadPhoto(estateId);
      const { isAnyDocumentWithError } = isAnyDocumentToSave
        ? await saveDocuments(event, estateId)
        : { isAnyDocumentWithError: false };

      if (!ownersSaved || !photoUploaded || isAnyDocumentWithError) {
        shouldProceed = false;
      }

      if (shouldProceed) {
        if (isEstateDirty || isEstateDetailsDirty) {
          toast.success(t('successMessages.EstateSavedSuccessfully'), {
            toastId: estateId,
            autoClose: 5000,
          });
        }

        if (shouldResetFormAfterSave) {
          copyOwners();
          setShouldResetFormAfterSave(false);
          setSavedId(null);
          clearDocuments();
          resetStepper();
          setIsCopy(true);
        }

        if (onSaveSuccess) {
          onSaveSuccess(estate, estateId, shouldResetFormAfterSave);
        } else if (!shouldResetFormAfterSave) {
          navigate(routes.estate(estateId), { withoutPrompt: true });
        }
      }
    }
  };

  const onSubmit =
    (resetStepper: () => void): SubmitHandler<EstateFormInterface> =>
    (form, event) => {
      if (isAnyDocumentEmpty) {
        return markEmptyDocuments();
      }

      if (areCopiedOwnersUnchanged) {
        return setUpAndOpenConfirmationModal({
          type: 'confirmation',
          translationKey: 'sameOwnerForCopiedEstate',
          shouldCloseOnSubmit: true,
          onSubmit: () => saveForm(event, form, resetStepper),
        });
      }

      return saveForm(event, form, resetStepper);
    };

  return onSubmit;
};

export default useSubmitEstateForm;
