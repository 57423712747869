import { useState } from 'react';
import { Checkbox, TableCell } from '@mui/material';
import { useUpdateEffect } from 'usehooks-ts';
import isDocumentExpired from 'utils/isDocumentExpired';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { AttachDocumentCellProps } from './types';
import { handleAttachDocument } from './utils';

const AttachDocumentCell = ({
  document,
  documentConfigData,
  dossierStatus,
  updateDocuments,
  ...context
}: AttachDocumentCellProps) => {
  const [attached, setAttached] = useState(document.attached);
  const [isProcessing, setIsProcessing] = useState(false);

  useUpdateEffect(() => {
    setAttached(document.attached);
  }, [document.attached]);

  const toggleAttachDocuments = async () => {
    setIsProcessing(true);
    const { ok } = await handleAttachDocument(document, context);
    if (ok) {
      await updateDocuments();
    }
    setIsProcessing(false);
  };

  const isExpired = isDocumentExpired(documentConfigData, document.date);

  const isDisabled =
    !shouldDossierFeatureBeActive(dossierStatus) ||
    isProcessing ||
    document.sentOut ||
    !document.isAttachable ||
    (isExpired && !document.attached);

  return (
    <TableCell sx={({ mixins }) => mixins.tableCellStyles}>
      {!document.isMissing &&
      document.documentClassification !== 'DocumentPlaceholder' ? (
        <Checkbox
          color="primary"
          checked={attached}
          onChange={toggleAttachDocuments}
          disabled={isDisabled}
        />
      ) : null}
    </TableCell>
  );
};

export default AttachDocumentCell;
