import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import Button from 'atoms/Button';
import { ConfigContext } from 'contexts/config/ConfigContext';
import ProductIcon from 'icons/ProductIcon';
import CardWithPlus from 'templates/CardWithPlus';
import { sortByOrder } from 'utils/object';
import shouldDossierFeatureBeActive from 'utils/shouldDossierFeatureBeActive';
import { addProduct } from 'api/dossier/products/requests';
import { ProductInterface, ProductName } from 'api/dossier/products/types';
import { DossierViewInterface } from 'api/dossier/types';
import { getProductButtonGridSize, shouldProductBeDisabled } from './utils';

interface Props {
  products: ProductInterface[];
  fetchProducts: () => Promise<void>;
}

const AddNewProductCard = ({ products, fetchProducts }: Props) => {
  const { t } = useTranslation(['dossierProducts', 'errors']);
  const [addingProduct, setAddingProduct] = useState<ProductName | null>(null);

  const dossier = useOutletContext<DossierViewInterface>();
  const { rentalDossierChecklist } = useContext(ConfigContext);

  const onAdd = (name: ProductName) => async () => {
    setAddingProduct(name);
    const { ok } = await addProduct(dossier.id, { productName: name });
    if (ok) {
      await fetchProducts();
    }
    setAddingProduct(null);
  };

  return (
    <CardWithPlus
      maxWidth={
        !products.length ? { sm: '100%', md: '50%', lg: '100%', xl: '50%' } : 'unset'
      }
    >
      <Grid height="100%" container alignItems="center" spacing={1}>
        {rentalDossierChecklist.sort(sortByOrder).map((product) => (
          <Grid
            key={product.name}
            item
            xs={getProductButtonGridSize(rentalDossierChecklist.length)}
          >
            <Button
              fullWidth
              variant="text"
              color="secondary"
              onClick={onAdd(product.name)}
              disabled={
                !shouldDossierFeatureBeActive(dossier.status) ||
                !!addingProduct ||
                shouldProductBeDisabled(products, product)
              }
            >
              <Stack
                rowGap={1}
                justifyContent="center"
                alignItems="center"
                maxWidth={100}
              >
                {addingProduct === product.name ? (
                  <CircularProgress size={24} />
                ) : (
                  <ProductIcon name={product.name} />
                )}
                <Typography textAlign="center" variant="caption">
                  {t(`dossierProducts:${product.name}.name`)}
                </Typography>
              </Stack>
            </Button>
          </Grid>
        ))}
      </Grid>
    </CardWithPlus>
  );
};

export default AddNewProductCard;
