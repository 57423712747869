import { isEstateComposition } from 'formData/estate/details/utils';
import { EstateDetailsForm } from 'api/estate/types';
import { EstateFormInterface } from '../types';

export const getEstateCompositionValue = (
  { estate, estateDetails }: EstateFormInterface,
  prevFormState: Partial<EstateFormInterface>,
): EstateDetailsForm['estateComposition'] => {
  if (!isEstateComposition(estate)) {
    return null;
  }

  const {
    destination: prevDestination,
    type: prevType,
    subType: prevSubType,
  } = prevFormState.estate ?? {};

  if (prevDestination && prevDestination !== estate.destination) {
    if (
      estate.destination !== 'NonResidential' &&
      estate.subType?.match(/Apartment\dBedroom/g)
    ) {
      return { bedrooms: Number(estate.subType.match(/\d+/)?.[0]) };
    }
    return null;
  }

  const composition = estateDetails?.estateComposition ?? null;

  if (estate.destination !== 'NonResidential') {
    if (estate.subType?.match(/Apartment\dBedroom/g)) {
      return {
        ...composition,
        bedrooms: Number(estate.subType.match(/\d+/)?.[0]),
      };
    }
    if (
      (prevType && prevType !== estate.type) ||
      (prevSubType && prevSubType !== estate.subType)
    ) {
      return { ...composition, bedrooms: null };
    }
  }

  return composition;
};
